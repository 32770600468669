import * as React from "react";

import Topic from "../components/topic";

// markup
const ServicePage = () => {
  const post = {
    title: 'Hire Dedicated Node.js Developers | Offshore Node.js Development Company in India',
    metaDescription: 'Hire top Dedicated Node.js developers in India to build scalable, enterprise-grade, and agile web applications projects on an hourly/full-time basis. Hire now!',
    h1: 'Hire Dedicated NodeJS Developers',
    h1Subtext1: 'Build high performing, scalable and efficient custom applications using Node.js.',
    h1Subtext2: 'MatteCurve is the best NodeJS development company in India. We are a JavaScript company and have dedicated NodeJS developers working remotely. We work with fortune 500 companies and startups to build end-to-end solutions based on JavaScript ecosystem. Share your requirements and hire NodeJS developers within 7 days.',
    aboutSection: {
        title: 'About NODEJS',
        subTitle: 'Node.js is a JavaScript runtime built on Chrome\'s V8 JavaScript engine.',
        paragraph1: " Node.js was initially released in 2009 with objective to build scalable network applications. Node.js is an open-source and cross-platform JavaScript runtime environment for executing JavaScript code on the server-side. It was built on Chrome’s V8 JavaScript engine with scalability in mind.",
        paragraph2: "This allows developers to design and develop feature-rich and attractive apps and websites for their business. Node.js is lightweight and fast real-time web applications communication or high IO with less computation. Node.js is among the most popular technologies and it follows an event driven, non-blocking I/O model.",
        paragraph3: "Node.js has large set of inbuilt modules which provides great capability to start work on any project. NPM modules are a great help in development of Node.js based projects. Express.js and Hapi are popular node.js frameworks to start quickly. As NodeJS is based on JavaScript, NodeJS developer can easily work on both back-end and front-end applications.",
        keyPoints: [
            "Node.js uses a ‘JavaScript everywhere’ concept where the web application development depends on JavaScript for both client-side and server-side programming.",
            "Node.js is a highly extensible technology that can be customized as per business and project requirements.",
            "Provides the power to build scalable and faster real-time applications. Strengthen the back-end with event-driven & speedy Node.js development services.",
            "Node.js is available to virtually any developer no matter their preferred operating system.",
            "It is mobile friendly offering improved app response time, development time, Scalability and productivity.",
            "Node.js is mostly implemented in C and C++ focusing on performance and low memory consumption.",
            "Node.js have a rich portfolio depicting their work and are capable of handling both large-scale and small-scale projects."
        ],
    },
    statsSection: {
        paragraph: "With its growing popularity, more and more enterprises will experiment with this simple yet performant JavaScript environment. Fortune 500 companies favor Node.js over other frameworks. Node js has been used by companies like LinkedIn, Netflix, Uber, Trello, PayPal, NASA, eBay, Medium, Groupon and many more.",
    },
    benefitsSection: {
        title: "Benefits of Hiring Dedicated NodeJS Developers",
        keyPoints: [
            "Improve Project Outcomes",
            "Cost-effective",
            "No investment On Hardware/Software Requirement",
            "Get Access to Skilled Developers",
            "Agile Approach",
            "Collaborative Engagement",
            "Painless Project Management",
            "Greater focus on business"
        ],
        image: "https://images.unsplash.com/photo-1603404235009-71c1f80e31fc?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80",
    },
    ctaTexts: [
        "Hire Dedicated NodeJS Developers?",
        "Hire Dedicated NodeJS Developers from MatteCurve?",
    ],
    why: {
        title: "Why Choose MatteCurve for NodeJS Development?",
        paragraph: "MatteCurve is one of the best NodeJS API development companies in India which helps clients to handle high-speed data, power to build scalable and faster real-time applications. and enhance the data exchange and retrieval performance through innovative enterprise web solutions.",
    }
  };


  return (
    <Topic post={post}></Topic>
  )
}

export default ServicePage;
